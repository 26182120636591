import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TopSellingN3() {
  const [topSellingNumbers, setTopSellingNumbers] = useState([]);
  const [nextPeriod, setNextPeriod] = useState('');
  const [updateTime, setUpdateTime] = useState('');

  useEffect(() => {
    // Fetch ข้อมูลงวดถัดไปและข้อมูลยอดขายจาก API
    const fetchNextPeriodAndTopSellingNumbers = async () => {
      try {
        const response = await axios.get('/top-selling-numbers'); // ดึงข้อมูลงวดถัดไปและยอดขาย
        if (response.data.salesData.length > 0) {
          const periodDate = new Date(response.data.perioddate);
          periodDate.setHours(periodDate.getHours() + 7);

          // แปลงวันที่งวดเป็นรูปแบบไทย
          const formattedPeriodDate = periodDate.toLocaleDateString('th-TH', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          });

          // แปลงเวลา updatetime ที่ได้จากฐานข้อมูล
          const updateTime = new Date(response.data.updateTime);

          const formattedUpdateTime = updateTime.toLocaleString('th-TH', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });

          // ตั้งค่าข้อมูลงวดถัดไปและเวลาอัปเดต
          setNextPeriod(formattedPeriodDate);
          setUpdateTime(formattedUpdateTime);
          
          // ตั้งค่าข้อมูลยอดขาย
          setTopSellingNumbers(response.data.salesData); 
        }
      } catch (error) {
        console.error('Error fetching top-selling numbers:', error);
      }
    };

    fetchNextPeriodAndTopSellingNumbers();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="top-selling-box">
            <h2>งวดวันที่ {nextPeriod}</h2>
            <p>อัปเดตจากยอดขาย N3</p>
            <p>เมื่อวันที่ {updateTime}</p> {/* แสดงวันที่และเวลาอัปเดต */}
            <table className="top-selling-table">
              <thead>
                <tr>
                  <th>ลำดับที่</th>
                  <th>เลขสลากฯ</th>
                  <th>ยอดขาย</th>
                </tr>
              </thead>
              <tbody>
                {topSellingNumbers.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td> {/* ลำดับ */}
                    <td>{item.number}</td> {/* เลขสลาก */}
                    <td>{item.sales}</td> {/* ยอดขาย */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSellingN3;
