import React, { useState, useEffect, useCallback  } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // สำหรับ CSS

function LottoStat() {
  const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
  const [displayMessage, setDisplayMessage] = useState('งวดต่อไป');
  const [filter, setFilter] = useState('nextPeriod'); 
  const [isLoading, setIsLoading] = useState(false);
  const [numberStats, setNumberStats] = useState({});
  const [st2numStats, setSt2numStats] = useState({});

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true); 


  const [availableYears, setAvailableYears] = useState([]);
  const [showYearModal, setShowYearModal] = useState(false);
  const [loadYears, setLoadYears] = useState(false);
  const handleCloseYearModal = () => setShowYearModal(false);
  const handleShowYearModal = () => setShowYearModal(true);

  const [hasExcludedDate, setHasExcludedDate] = useState(false);
 
  const selectYear = (year) => {
    console.log(`Year selected: ${year}`); // ตรวจสอบในคอนโซลว่าปีถูกเลือกอย่างถูกต้อง
    setFilter(`yearly_${year}`); // ตั้งค่า filter ตามปีที่เลือก
    setDisplayMessage(`ปี ${year + 543}`); // อัพเดตข้อความที่จะแสดง
    handleCloseYearModal(); // ปิด modal
    // คุณอาจต้องดึงข้อมูลสลากกินแบ่งของปีที่เลือกหลังจากนี้
  };

  // Fetch ข้อมูลปี
  useEffect(() => {
    if (loadYears) {
      const fetchYears = async () => {
        setIsLoading(true);
        let url = '/years';
        try {
          const response = await fetch(url);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const years = await response.json();
          setAvailableYears(years);
          handleShowYearModal();
        } catch (error) {
          console.error('Error fetching years:', error);
        } finally {
          setIsLoading(false);
          setLoadYears(false); // รีเซ็ตการโหลดข้อมูลปี
        }
      };
      fetchYears();
    }
  }, [loadYears]); // ขึ้นอยู่กับสถานะ loadYears

  
  const [availableMonths, setAvailableMonths] = useState([]);
  const [showMonthModal, setShowMonthModal] = useState(false);
  const [loadMonths, setLoadMonths] = useState(false);
  const handleCloseMonthModal = () => setShowMonthModal(false);
  const handleShowMonthModal = () => setShowMonthModal(true);

  const calculateNumberFrequency = useCallback((data) => {
    const initialFrequency = Array.from({ length: 10 }, () => 0);
    const frequency = [...initialFrequency];
    const st2numFrequency = [...initialFrequency];

    data.forEach(item => {
        if (item['2NUM'] !== '' && convertDateToYYYYMMDD_ENG(item.date) !== '20200401') {
            const nums = item['2NUM'].toString().padStart(2, '0').split('');
            nums.forEach(num => {
                frequency[parseInt(num, 10)]++;
            });
        }
        if (item['1ST_6NUM'] && convertDateToYYYYMMDD_ENG(item.date) !== '20200401') {
            const lastTwoDigits = item['1ST_6NUM'].toString().slice(-2);
            st2numFrequency[parseInt(lastTwoDigits[0], 10)]++;
            st2numFrequency[parseInt(lastTwoDigits[1], 10)]++;
        }
    });

    setNumberStats(frequency);
    setSt2numStats(st2numFrequency);
  }, []); // เพิ่ม dependencies หากฟังก์ชันนี้ขึ้นกับ props หรือ state

  const selectMonth = (month) => {
    console.log(`Month selected: ${month}`); 
    setFilter(`monthy_${month}`); 
    setDisplayMessage(`เดือน ${months[month-1]}`);
    handleCloseMonthModal(); 
    // Fetch lottery data for the selected month
  };

  useEffect(() => {
    if (loadMonths) {
      // Here, you would fetch the months similar to how you fetch years.
      // You'll need to set up a corresponding endpoint in your server code.
      const fetchMonths = async () => {
        setIsLoading(true);
        let url = '/months'; // You will need to implement this endpoint on the server
        try {
          const response = await fetch(url);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const months = await response.json();
          setAvailableMonths(months);
          handleShowMonthModal();
        } catch (error) {
          console.error('Error fetching months:', error);
        } finally {
          setIsLoading(false);
          setLoadMonths(false);
        }
      };
      fetchMonths();
    }
  }, [loadMonths]);

  // Fetch ข้อมูลสลากกินแบ่งตาม filter
  useEffect(() => {
    if (filter && filter !== 'specifyYear' && filter !== 'specifyMonth') {
      setIsLoading(true);
      let url;
      if (filter.startsWith('yearly_')) {
        const year = filter.split('_')[1]; // แยกเพื่อได้ปี
        url = `/data?filter=${filter.split('_')[0]}&year=${year}`; // ปรับปรุง URL ให้สอดคล้องกับ filter 
      } else if (filter.startsWith('monthy_')) {
          const month = filter.split('_')[1]; // แยกเพื่อได้ปี
          url = `/data?filter=${filter.split('_')[0]}&month=${month}`; // ปรับปรุง URL ให้สอดคล้องกับ filter
      } else {
        url = `/data?filter=${filter}`;
      }
      
      
      const fetchData = async () => {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            calculateNumberFrequency(data);
            const hasExcludedDate = data.some(item => convertDateToYYYYMMDD_ENG(item.date) === '20200401');
            setHasExcludedDate(hasExcludedDate);
        } catch (error) {
            console.error('Error fetching lotto data:', error);
        } finally {
            setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [filter, calculateNumberFrequency]); // ขึ้นอยู่กับสถานะ filter

  useEffect(() => {
    // ดึงข้อมูลวันที่ของงวดต่อไปเมื่อ component ถูกโหลด
    const fetchNextPeriodDate = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('/nextPeriod');
        const data = await response.json();
        if (data && data.perioddate) {
          const nextPeriodDate = formatDate(data.perioddate);
          setDisplayMessage(`งวดต่อไป - ${nextPeriodDate}`);
        }
      } catch (error) {
        console.error('Error fetching next period date:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    // เรียกใช้เมื่อ component ถูกโหลดครั้งแรก
    fetchNextPeriodDate();
  }, []); // Dependency array เป็นค่าว่าง เพื่อให้รันเพียงครั้งเดียวเมื่อโหลด

  const handleButtonClick = (selectedFilter, message) => {
    if (selectedFilter === 'currentPeriod') {
      setIsLoading(true);
      fetch('/latestPeriod')
        .then(response => response.json())
        .then(data => {
          const latestPeriodDate = formatDate(data.perioddate);
          setDisplayMessage(`งวดล่าสุด - ${latestPeriodDate}`);
          setFilter(selectedFilter);
        })
        .catch(error => console.error('Error:', error))
        .finally(() => setIsLoading(false));
    } else if (selectedFilter === 'nextPeriod') {
      setIsLoading(true);
      fetch('/nextPeriod')
        .then(response => response.json())
        .then(data => {
          const nextPeriodDate = formatDate(data.perioddate);
          setDisplayMessage(`งวดต่อไป - ${nextPeriodDate}`);
          setFilter(selectedFilter);
        })
        .catch(error => console.error('Error:', error))
        .finally(() => setIsLoading(false));
    } else if (selectedFilter === 'specifyYear') {
      // ตั้งค่าให้โหลดข้อมูลปี
      setLoadYears(true);
    } else if (selectedFilter === 'specifyMonth') {
      setLoadMonths(true); // โหลดข้อมูลเดือน
    } else {
      // อัพเดต filter และแสดงข้อความ
      setDisplayMessage(message);
      setFilter(selectedFilter);
    }
  };

  

  const convertDateToYYYYMMDD_ENG = (dateString) => {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // แปลงให้เป็นรูปแบบ 2 หลัก
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    
    return `${year}${month}${day}`;
  };
 
  const convertDateToYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear() + 543;

    // แปลงให้เป็นรูปแบบ 2 หลัก
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    
    return `${year}${month}${day}`;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear() + 543; // แปลงเป็นปีพุทธศักราช

    // แปลงให้เป็นรูปแบบ 2 หลัก
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    //const [day, month, year] = dateString.split('/');
    const buddhistYear = parseInt(year, 10);
    const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
    const formattedDate = `${day} ${months[parseInt(month, 10) - 1]} ${buddhistYear}`;
    return formattedDate;
  }

  /*
  // คำนวณความถี่สูงสุดและต่ำสุด
  const maxFrequency = Math.max(...Object.values(numberStats));
  const minFrequency = Math.min(...Object.values(numberStats));
  const frequenciesSorted = Object.entries(numberStats).sort((a, b) => b[1] - a[1]);
  const secondMaxFrequency = frequenciesSorted[1] ? frequenciesSorted[1][1] : maxFrequency;
  const frequenciesSortedAsc = Object.entries(numberStats).sort((a, b) => a[1] - b[1]);
  const secondMinFrequency = frequenciesSortedAsc[1] ? frequenciesSortedAsc[1][1] : minFrequency;

  const getNumberStyle = (freq) => {
    if (freq === maxFrequency) {
      return { color: 'rgb(0, 128, 0)' }; // Green
    } else if (freq === secondMaxFrequency) {
      return { color: 'rgb(0, 0, 255)' }; // Blue
    } else if (freq === minFrequency) {
      return { color: 'rgb(255, 0, 0)' }; // Red
    } else if (freq === secondMinFrequency) {
      return { color: 'rgb(255, 165, 0)' }; // Orange
    } else {
      return {};
    }
  };
*/

  // ฟังก์ชันในการคำนวณความถี่และกำหนดสไตล์ตามความถี่
  const calculateFrequencyAndStyle = (stats) => {
    const maxFrequency = Math.max(...Object.values(stats));
    const minFrequency = Math.min(...Object.values(stats));
    const frequenciesSorted = Object.entries(stats).sort((a, b) => b[1] - a[1]);
    const secondMaxFrequency = frequenciesSorted[1] ? frequenciesSorted[1][1] : maxFrequency;
    const frequenciesSortedAsc = Object.entries(stats).sort((a, b) => a[1] - b[1]);
    const secondMinFrequency = frequenciesSortedAsc[1] ? frequenciesSortedAsc[1][1] : minFrequency;
  
    const getNumberStyleForTop = (freq) => {
      if (freq === maxFrequency) {
        return { color: 'rgb(0, 128, 0)', fontSize: '1.8em', fontWeight: 'bold', verticalAlign: 'middle' }; // Green
      } else if (freq === secondMaxFrequency) {
        return { color: 'rgb(0, 0, 255)', fontSize: '1.5em', fontWeight: 'bold', verticalAlign: 'middle' }; // Blue
      } else {
        return { color: 'rgb(0, 0, 0)', fontSize: '1em', fontWeight: 'normal', verticalAlign: 'middle' };
      }
    };
  
    const getNumberStyleForBottom = (freq) => {
      if (freq === maxFrequency) {
        return { color: 'rgb(0, 128, 0)', verticalAlign: 'middle' }; // Green
      } else if (freq === secondMaxFrequency) {
        return { color: 'rgb(0, 0, 255)', verticalAlign: 'middle' }; // Blue
      } else if (freq === minFrequency) {
        return { color: 'rgb(255, 0, 0)', verticalAlign: 'middle' }; // Red
      } else if (freq === secondMinFrequency) {
        return { color: 'rgb(255, 165, 0)', verticalAlign: 'middle' }; // Orange
      } else {
        return { color: 'rgb(0, 0, 0)', verticalAlign: 'middle' };
      }
    };
  
    const getNumberStyle = (freq) => {
      if (freq === maxFrequency) {
        return { color: 'rgb(0, 128, 0)', fontSize: '2em', fontWeight: 'bold', verticalAlign: 'middle' }; // Green
      } else if (freq === secondMaxFrequency) {
        return { color: 'rgb(0, 0, 255)', fontSize: '1.5em', fontWeight: 'bold', verticalAlign: 'middle' }; // Blue
      } else {
        return { color: 'black', fontSize: '1em', fontWeight: 'normal', verticalAlign: 'middle' };
      }
    };
  
    return { getNumberStyle, getNumberStyleForTop, getNumberStyleForBottom, maxFrequency, minFrequency, secondMaxFrequency, secondMinFrequency };
  };

  // ใช้งานฟังก์ชันกับ numberStats สำหรับเลขท้าย 2 ตัว
//const { getNumberStyle: getNumberStyle2num } = calculateFrequencyAndStyle(numberStats);
const { getNumberStyle: getNumberStyle2num, getNumberStyleForTop: getNumberStyleForTop2num, getNumberStyleForBottom: getNumberStyleForBottom2num } = calculateFrequencyAndStyle(numberStats);


// ใช้งานฟังก์ชันกับ st2numStats สำหรับเลขท้าย 2 ตัวของรางวัลที่ 1
//const { getNumberStyle: getNumberStyleSt2num } = calculateFrequencyAndStyle(st2numStats);
const { getNumberStyle: getNumberStyleSt2num, getNumberStyleForTop: getNumberStyleForTopSt2num, getNumberStyleForBottom: getNumberStyleForBottomSt2num } = calculateFrequencyAndStyle(st2numStats);

// ตอนนี้คุณสามารถใช้ getNumberStyle1 และ getNumberStyle2 เพื่อกำหนดสไตล์ตามความถี่สำหรับแต่ละกรณีได้


// คำนวณและสร้างสตริงสำหรับแสดงตัวเลขที่มีโอกาสออกซ้ำ
const getRepeatedNumbersSummary = (stats) => {
  return Object.entries(stats)
    .filter(([_, freq]) => freq > 0) // กรองเฉพาะตัวเลขที่มีความถี่มากกว่า 0
    .sort((a, b) => b[1] - a[1]) // เรียงลำดับจากมากไปน้อย
    .map(([number, _]) => number); // แปลงเป็นอาร์เรย์ของเลขที่ต้องการ
};

// ใช้งานฟังก์ชันกับ numberStats และ st2numStats
const repeatedNumbersFor2num = getRepeatedNumbersSummary(numberStats);
const repeatedNumbersForSt2num = getRepeatedNumbersSummary(st2numStats);


  
  return (
    <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="award-history-box">
                <h2>สถิติผลสลากกินแบ่งรัฐบาล {displayMessage && `(${displayMessage})`} 
                    {hasExcludedDate && (
                        <>
                        <div className="info" onClick={handleShowModal}>
                            <i className="fas fa-info-circle fa-sm"></i>
                            <span>วันที่ 1 เมษายน 2563 ไม่ถูกนำมาคำนวน</span>
                        </div>
                        </>
                    )}
                </h2>
              {isLoading && (filter === 'specifyYear' && filter === 'specifyMonth') ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
        
                {/* ส่วนของปุ่ม */}
                <div className="mt-3"> {/* ใส่ margin bottom */}
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('last36', '36 งวดล่าสุด')}>36 งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('last24', '24 งวดล่าสุด')}>24 งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('currentMonth', 'เดือนปัจจุบัน')}>เดือนปัจจุบัน</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('nextMonth', 'เดือนถัดไป')}>เดือนถัดไป</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('currentPeriod', 'งวดล่าสุด')}>งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('nextPeriod', 'งวดต่อไป')}>งวดต่อไป</button>
                  <button className="btn btn-success btn-extra-sm" onClick={() => handleButtonClick('specifyYear', 'ปี')}>ปี</button>
                  <button className="btn btn-success btn-extra-sm" onClick={() => handleButtonClick('specifyMonth', 'เดือน')}>เดือน</button>
                </div>

                <div className="dashboard-panel">
                  <div className="number-summary">
                    <div className="summary-container">
                      <span>เลขท้าย 2 ตัว</span>
                      <div className="repeated-numbers">
                        {repeatedNumbersFor2num.map((number, index) => (
                          <span key={index} style={getNumberStyleForTop2num(numberStats[number])}>
                            {number}
                          </span>
                        ))}
                      </div>
                    </div>
                    <hr />
                  </div>
                    <div className="table-responsive">
                        <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th>เลข</th>
                            {Object.entries(numberStats).map(([number, freq]) => (
                                <th key={number} style={getNumberStyleForBottom2num(freq)}>
                                {number}
                                </th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>ครั้ง</th>
                            {Object.entries(numberStats).map(([number, freq], index) => (
                                <td key={index}>{freq}</td>
                            ))}
                          </tr>
                        </tbody>
                        </table>
                    </div>
                    <div className="margin"/>
                      <div className="number-summary">
                        <div className="summary-container">
                          <span>เลขท้าย 2 ตัวรางวัลที่ 1</span>
                          <div className="repeated-numbers">
                            {repeatedNumbersForSt2num.map((number, index) => (
                              <span key={index} style={getNumberStyleForTopSt2num(st2numStats[number])}>
                                {number}
                              </span>
                            ))}
                          </div>
                        </div>
                        <hr />
                      </div>
                    <div className="table-responsive">
                        <table className="table table-borderless">
                        <thead>
                            <tr>
                            <th>เลข</th>
                            {Object.entries(st2numStats).map(([number, freq]) => (
                                <th key={number} style={getNumberStyleForBottomSt2num(freq)}>
                                {number}
                                </th>
                            ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <th>ครั้ง</th>
                            {Object.entries(st2numStats).map(([number, freq], index) => (
                                <td key={index}>{freq}</td>
                            ))}
                            </tr>
                        </tbody>
                        </table>
                    </div>

                    <Modal show={showModal} onHide={handleCloseModal} className="custom-modal">
                      <Modal.Header closeButton>
                        <Modal.Title>แจ้งให้ทราบ</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          เนื่องด้วยสถานการณ์โควิด ทำให้งวดประวันที่ 1 เมษายน 2563 เลื่อนไปออกไปวันที่ 16 พฤษภาคม 2563<br />
                          <br />
                          แต่ระบบจะยึดวันออกรางวัลสลาก คือ วันที่ 1 เมษายน 2563 ถึงแม้จะนำไปออกวันที่ 16 พฤษภาคม 2563<br />
                          <br />
                          ซึ่งทำให้การคำนวนค่าสถิติมองได้ทั้งแนวสองทาง ว่าควรจะเป็นของ 1 เมษายน 2563 หรือ 16 พฤษภาคม 2563<br />
                          <br />
                          ด้วยเหตุดังกล่าวทางเรามองว่าไม่เหมาะกับการนำมาคำนวนเพื่อหาสถิติกรณีต้องการความแม่นยำ จึงนำค่าสถิติของ 1 เมษายน 2563 ออกจากการคำนวน
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          ปิด
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={showYearModal} onHide={handleCloseYearModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>เลือกปีที่ต้องการดูผลสลากกินแบ่ง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          availableYears.map(year => (
                            <button className="yearly-btn" key={year} onClick={() => selectYear(year)}>
                              {year + 543}
                            </button>
                          ))
                        )}
                      </Modal.Body>
                    </Modal>

                    <Modal show={showMonthModal} onHide={handleCloseMonthModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>เลือกปีที่ต้องการดูผลสลากกินแบ่ง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          availableMonths.map(month => (
                            <button className="monthy-btn" key={month} onClick={() => selectMonth(month)}>
                              {months[parseInt(month, 10) - 1]}
                            </button>
                          ))
                        )}
                      </Modal.Body>
                    </Modal>

                </div>
              
                </>
            )}
      </div>
            </div>
          </div>
        </div>
    );
}

export default LottoStat;