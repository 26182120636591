import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LotteryResultsN3.css'; // นำเข้า CSS เฉพาะของ N3

function LotteryResultsN3() {
  const [nextPeriod, setNextPeriod] = useState(null);
  const [results, setResults] = useState({
    last2Digits: 'XX',
    last3Digits: 'XXX',
    permutations: ['XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
    specialPrize: 'XXXXXXXXXXXX', // รางวัลพิเศษ 12 หลัก
  });

  useEffect(() => {
    // ดึงข้อมูลงวดล่าสุดจาก server
    axios.get('/latestPeriod')
      .then(response => {
        const perioddate = new Date(response.data.perioddate);
        perioddate.setHours(perioddate.getHours() + 7);
        setNextPeriod(perioddate);

        // ส่งค่า perioddate ไปยัง /latestPeriodResults
        axios.get('/latestPeriodResults', {
          params: {
            perioddate: perioddate.toISOString().split('T')[0] // ส่งวันที่เป็น YYYY-MM-DD
          }
        })
        .then(resultResponse => {
          // ถ้ามีข้อมูลใน database ให้ใช้ข้อมูลนั้น
          if (resultResponse.data) {
            const latestResults = resultResponse.data;

            const firstPrize = latestResults['1ST_6NUM'] || 'XXXXXX';

            // คำนวณ 3 ตัวท้ายของรางวัลที่ 1
            const last3Digits = firstPrize.slice(-3);

            // คำนวณ 3 ตัวกลับของรางวัลที่ 1 แต่ตัดตัวที่ตรงกับ 3 ตัวท้ายออก
            const getPermutations = (str) => {
              if (str.length !== 3) return [];
              const [a, b, c] = str;
              const allPermutations = [
                `${a}${b}${c}`, `${a}${c}${b}`, `${b}${a}${c}`, `${b}${c}${a}`, `${c}${a}${b}`, `${c}${b}${a}`
              ];
              // เอาแค่ตัวที่ไม่ตรงกับ last3Digits และเรียงลำดับจากน้อยไปมาก
              return allPermutations
                .filter(item => item !== str)
                .sort() // จัดเรียงจากน้อยไปมาก
                .slice(0, 5); // เอาแค่ 5 ตัวแรก
            };
            const permutations = getPermutations(last3Digits);
            
            setResults({
              last2Digits: latestResults['2NUM'] || 'XX',
              last3Digits: last3Digits,
              permutations: permutations,
              specialPrize: latestResults['N3'] || 'XXXXXXXXXXXX', // เพิ่มรางวัลพิเศษ 12 หลัก
            });
          } else {
            setResults({
              last2Digits: 'XX',
              last3Digits: 'XXX',
              permutations: ['XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
              specialPrize: 'XXXXXXXXXXXX', // ค่าเริ่มต้น
            });
          }
        })
        .catch(error => {
          console.error('Error fetching period data:', error);
          setResults({
            last2Digits: 'XX',
            last3Digits: 'XXX',
            permutations: ['XXX', 'XXX', 'XXX', 'XXX', 'XXX'],
            specialPrize: 'XXXXXXXXXXXX',
          });
        });
      })
      .catch(error => {
        console.error('Error fetching period data:', error);
        // ถ้ามีปัญหาในการดึงข้อมูลงวด ให้ใช้ค่าเริ่มต้นคือ XXX
        setResults({
          firstPrize: 'XXXXXX',
          front3Digits: ['XXX', 'XXX'],
          last3Digits: ['XXX', 'XXX'],
          last2Digits: 'XX',
        });
      });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 after-award">
          <div className="award-current-box">
            <div className="row row1">
              <div className="col-12 col-md-6 col-lg-8">
                <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล N3</h1>
                {nextPeriod && (
                  <h2>งวดประจำวันที่ {nextPeriod.getDate()} {nextPeriod.toLocaleString('th-TH', { month: 'long', year: 'numeric' })}</h2>
                )}
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  
              </div>
            </div>
            <div className="row row2">
              <div className="col-12 d-flex flex-column flex-md-row">
                <div className="n3-award1 d-flex flex-row flex-md-column">
                  <h2 className="label">รางวัลสามตรง</h2>
                  <div className="n3-award1-item">
                    <p className="n3-award1-item-sub">{results.last3Digits}</p>
                  </div>
                  <div className="prize-amount">รางวัลละ <span className="prize-amount-number">-</span> บาท/ใบ</div> {/* ข้อความแสดงรางวัล */}
                </div>
                <div className="n3-award2 d-flex flex-row flex-md-column">
                  <h2 className="label">รางวัลสามสลับหลัก</h2>
                  <div className="n3-award2-item">
                    {results.permutations.map((num, index) => (
                      <p key={index} className="n3-award2-item-sub">{num}</p>
                    ))}
                  </div>
                  <div className="prize-amount">รางวัลละ <span className="prize-amount-number">-</span> บาท/ใบ</div> {/* ข้อความแสดงรางวัล */}
                </div>
                <div className="n3-award3 d-flex flex-row flex-md-column">
                  <h2 className="label">รางวัลสองตรง</h2>
                  <div className="n3-award3-item">
                    <p className="n3-award3-item-sub">{results.last2Digits}</p>
                  </div>
                  <div className="prize-amount">รางวัลละ <span className="prize-amount-number">-</span> บาท/ใบ</div> {/* ข้อความแสดงรางวัล */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LotteryResultsN3;

/*
<div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 16 กันยายน 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      608662
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">203</p>
                      <p className="award2-item-sub">904</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">008</p>
                      <p className="award2-item-sub">048</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">37</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
*/
